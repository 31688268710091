<template>
  <div class="content-wrapper"></div>
</template>

<script>
export default {
  name: "index",
  components: {},
};
</script>

<style scoped></style>
